<template>
  <div class="">
    <v-tabs v-model="tab">
      <v-tab v-for="lang in langs" :key="lang.key">{{ lang.label }}</v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item v-for="lang in langs" :key="lang.key">
        <slot :name="lang.key"></slot>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import {
  ref,
  computed,
  onMounted,
  getCurrentInstance,
} from "@vue/composition-api";
import { useUtils as useUtilsI18n } from "@core/libs/i18n";

export default {
  setup(props) {
    const { t } = useUtilsI18n();
    const tab = ref("es");

    const langs = computed(() => [
      {
        key: "es",
        label: t("Spanish"),
      },
      {
        key: "fr",
        label: t("French"),
      },
    ]);

    return {
      tab,
      langs,

      t,
    };
  },
};
</script>

<style></style>

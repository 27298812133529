<template>
  <div>
    <v-btn
      color="primary"
      class="float-right mb-4"
      @click="onDrawerToogleAdd(true)"
    >
      {{ t("AddAnnouncement") }}
    </v-btn>

    <!--- Add Announcement -->
    <WrapperDrawer :title="t('AddAnnouncement')" v-model="drawerToogleAdd">
      <v-form ref="addAnnouncenmentForm" @submit.prevent="handleFormSubmit">
        <wrapper-tab-lang>
          <template slot="es">
            <v-row class="my-4">
              <v-col cols="12" md="12">
                <FormTextField
                  :label="t('Title Project')"
                  v-model="addAnnouncenmentFormData.title_es"
                  :rules="[validators.required]"
                  @onChangeInput="(v) => onChangeFormInputs(v, 'title_es')"
                />
              </v-col>
              <v-col cols="12" md="12">
                <FormTextArea
                  :label="t('Description Project')"
                  v-model="addAnnouncenmentFormData.description_es"
                  :rules="[validators.required]"
                />
              </v-col>
            </v-row>
          </template>
          <template slot="fr">
            <v-row class="my-4">
              <v-col cols="12" md="12">
                <FormTextField
                  :label="t('Title Project')"
                  v-model="addAnnouncenmentFormData.title_fr"
                  :rules="[validators.required]"
                  @onChangeInput="(v) => onChangeFormInputs(v, 'title_fr')"
                />
              </v-col>
              <v-col cols="12" md="12">
                <FormTextArea
                  :label="t('Description Project')"
                  :rules="[validators.required]"
                  v-model="addAnnouncenmentFormData.description_fr"
                />
              </v-col>
            </v-row>
          </template>
        </wrapper-tab-lang>

        <v-row>
          <v-col cols="12" md="12">
            <FormTextFieldPicker
              :label="t('Date Project')"
              :value="addAnnouncenmentFormData.initial_date"
              :rules="[validators.required]"
              @onChangeInput="(v) => onChangeFormInputs(v, 'initial_date')"
            />
          </v-col>
          <v-col cols="12" md="12">
            <v-radio-group v-model="addAnnouncenmentFormData.status" row>
              <v-radio :label="t('Enable')" :value="true"></v-radio>
              <v-radio :label="t('Disable')" :value="false"></v-radio>
            </v-radio-group>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-btn color="primary" type="submit" class="float-right mt-10">
              {{ t("Start Register") }}
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </WrapperDrawer>
  </div>
</template>

<script>
import WrapperDrawer from "@/components/general/WrapperDrawer";
import FormTextField from "@/components/forms/TextField";
import FormTextArea from "@/components/forms/TextArea";
import FormTextFieldPicker from "@/components/forms/TextFieldPicker";
import api from "@/services";

import {
  ref,
  reactive,
  onMounted,
  getCurrentInstance,
} from "@vue/composition-api";
import { required } from "@core/utils/validation";
import { useUtils as useUtilsI18n } from "@core/libs/i18n";
import WrapperTabLang from "@/components/general/WrapperTabLang";

export default {
  props: {},
  components: {
    WrapperDrawer,
    FormTextArea,
    FormTextField,
    FormTextFieldPicker,
    WrapperTabLang,
  },

  setup(props, { emit }) {
    const vm = getCurrentInstance().proxy;
    const { t } = useUtilsI18n();

    const drawerToogleAdd = ref(false);

    const addAnnouncenmentFormData = reactive({
      title_es: "",
      title_fr: "",
      description_es: "",
      description_fr: "",
      initial_date: null,
      status: true,
    });

    const onDrawerToogleAdd = (value) => {
      drawerToogleAdd.value = value;
    };

    const onChangeFormInputs = (value, property) => {
      addAnnouncenmentFormData[property] = value;
    };

    const handleFormSubmit = async () => {
      const isFormValid = vm.$refs.addAnnouncenmentForm.validate();

      if (!isFormValid) return;

      try {
        const { data: response } = await api.addAnnouncements(
          addAnnouncenmentFormData
        );

        const { data } = response;
        emit("onAddAnnouncement", data);

        vm.$alert(vm.$t("MessageSuccess"), null, "success");
      } catch (error) {
        vm.$alert(vm.$t("MessageError"), null, "error");
      }

      onDrawerToogleAdd(false);
    };

    return {
      addAnnouncenmentFormData,
      drawerToogleAdd,

      validators: {
        required,
      },

      handleFormSubmit,
      onChangeFormInputs,
      onDrawerToogleAdd,
      t,
    };
  },
};
</script>

<style></style>

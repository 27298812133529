<template>
  <v-dialog
    ref="dialog"
    v-model="modal"
    :return-value.sync="date"
    persistent
    width="290px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-model="dateFormat"
        :label="label"
        :prepend-icon="icons.mdiCalendar"
        :rules="rules"
        readonly
        v-bind="attrs"
        v-on="on"
      ></v-text-field>
    </template>

    <v-date-picker
      v-model="date"
      scrollable
      color="primary"
    >
      <v-btn
        text
        color="primary"
        @click="modal = false"
      >
        Cancel
      </v-btn>
      <v-btn
        text
        color="primary"
        @click="$refs.dialog.save(date)"
      >
        OK
      </v-btn>
    </v-date-picker>
  </v-dialog>
</template>

<script>
import { ref, watch } from '@vue/composition-api'
import { mdiCalendar } from '@mdi/js'

export default {
  props: {
    label: {
      default: null,
      type: String
    },
    value: {
      default: null
    },
    rules: {
      type: Array,
      default: () => []
    }
  },
  setup({ value }, { emit }) {
    const formatDate = dates => {
      if (!dates) return null
      const [year, month, day] = dates.split('-')

      return `${year}/${month}/${day}`
    }
    
    const date = ref(value ?? new Date().toISOString().substr(0, 10));
    const dateFormat = ref(null);
    const modal = ref(false);

    watch(date, () => {
      dateFormat.value = formatDate(date.value);
      emit('onChangeInput', dateFormat.value)
    })

    return {
      date,
      dateFormat,
      modal,

      // icons
      icons: {
        mdiCalendar,
      },
    }
  },
}
</script>
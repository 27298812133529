<template>
  <div>
    <TitleSection uppercase big :title="t('Announcement')">
      <announcement-add @onAddAnnouncement="onAddAnnouncement" v-if="false" />
    </TitleSection>

    <v-row>
      <v-col
        cols="12"
        xs="12"
        lg="6"
        v-for="(announcement, index) in announcenments"
        :key="index"
      >
        <AnnouncementItem :announcement="announcement">
          <template slot="admin-actions">
            <div class="d-flex justify-end g10">
              <announcement-edit :announcement="announcement" />

              <v-btn color="primary" small @click="goAnnouncementProjects(announcement)"
                >{{ $t('See projects') }}</v-btn
              >
              <v-btn small @click="goAnnouncementTasks(announcement)"
                >{{ $t('See tasks') }}</v-btn
              >
            </div>
          </template>
        </AnnouncementItem>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import TitleSection from "@/components/general/TitleSection";
import AnnouncementItem from "@/components/announcenments/AnnouncementItem";
import WrapperDrawer from "@/components/general/WrapperDrawer";
import FormTextArea from "@/components/forms/TextArea";
import FormTextFieldPicker from "@/components/forms/TextFieldPicker";

import {
  ref,
  reactive,
  onMounted,
  getCurrentInstance,
  watch,
} from "@vue/composition-api";
import { required } from "@core/utils/validation";
import api from "@/services";
import { useUtils as useUtilsI18n } from "@core/libs/i18n";
import { mdiBookEditOutline, mdiFolderInformationOutline } from "@mdi/js";
import { useRouter } from "@core/utils";
import AnnouncementEdit from '@/components/announcenments/AnnouncementEdit';
import AnnouncementAdd from '@/components/announcenments/AnnouncementAdd';

export default {
  components: {
    TitleSection,
    AnnouncementItem,
    WrapperDrawer,
    FormTextArea,
    FormTextFieldPicker,
    AnnouncementEdit,
    AnnouncementAdd,
  },
  setup() {
    const vm = getCurrentInstance().proxy;
    const { t } = useUtilsI18n();
    const { router } = useRouter();

    const announcenments = ref([]);
    const drawerToogleAdd = ref(false);

    const addAnnouncenmentFormData = reactive({
      title: null,
      init_date: null,
    });

    const onChangeFormInputs = (value, property) => {
      addAnnouncenmentFormData[property] = value;
    };

    const onAddAnnouncenmentOpen = () => {
      drawerToogleAdd.value = true;
    };

    const onDrawerToogleAdd = (value) => {
      drawerToogleAdd.value = value;
    };

    const handleFormSubmit = () => {
      const isFormValid = vm.$refs.addAnnouncenmentForm.validate();

      if (!isFormValid) return;
    };

    const goAnnouncementProjects = ({ id }) => {
      router.push({ name: "admin.announcements.show", params: { id } });
    };

    const goAnnouncementTasks = ({ id }) => {
      router.push({ name: "admin.announcements.tasks", params: { id } });
    };

    const getAnnouncenments = async () => {
      try {
        const { data: response } = await api.getAnnouncenments();
        const { data } = response;
        announcenments.value = data;
      } catch (error) {}
    };

    const onAddAnnouncement = (addAnnouncement) => {
      announcenments.value.push(addAnnouncement);
    }

    onMounted(() => {
      getAnnouncenments();
    });

    return {
      addAnnouncenmentFormData,
      announcenments,
      drawerToogleAdd,

      validators: {
        required,
      },

      icons: {
        mdiBookEditOutline,
        mdiFolderInformationOutline,
      },

      onAddAnnouncement,
      onChangeFormInputs,
      onAddAnnouncenmentOpen,
      onDrawerToogleAdd,
      handleFormSubmit,
      goAnnouncementProjects,
      goAnnouncementTasks,

      t,
    };
  },
};
</script>

<style></style>
